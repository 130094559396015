<template>
  <div>
    <h1>PAGE NOT FOUND</h1>
  </div>
</template>

<script>
export default {
  name: "error"
};
</script>

<style>
/* your style */
</style>
